import React from 'react';
import { render } from 'react-dom';
import CookieBanner from '../../../components/shared/cookie_banner/CookieBanner'

const cookieDiv = document.querySelector('#cookie-banner');

if (cookieDiv) {
  if (cookieDiv.innerHTML === '') {
    render(
      <CookieBanner/>,
      cookieDiv
    );
  }
}
