import React, { Component } from 'react';
import Cookies from 'js-cookie';
import Button from '@material-ui/core/Button';

const cookieBannerName = 'keycard-cookie-banner';

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    this.setState({visible: !Cookies.get(cookieBannerName)})
  }

  setCookie = () => {
    this.setState({visible: false});
    Cookies.set(cookieBannerName, true, { expires: 365 * 2 });
  };

  render() {
    return (
      <div id='page-menu' className={`${this.state.visible ? '' : 'display-none'}`}>
        <div id='page-menu-wrap'>
          <div className='container clearfix cookies-banner'>
            <div className='cookies-banner-text'>
              keycard.sk používa súbory cookies pre váš lepší online zážitok.
            </div>
            <a className='cookies-more-info padding-left-10 color-black' target='_blank' href='https://sk.wikipedia.org/wiki/HTTP_cookie'>
              Viac informácii...
            </a>
            <div className='float-right'>
              <Button size='small' variant='raised' onClick={() => this.setCookie()}>
                Rozumiem
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CookieBanner;
